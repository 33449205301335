import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { FunsMobileHeaderComponent } from './components/shared/funs-mobile-header/funs-mobile-header.component';
import { FunsMobileFooterComponent } from './components/shared/funs-mobile-footer/funs-mobile-footer.component';
import { FunsMobileSpinnerComponent } from './components/shared/funs-mobile-spinner/funs-mobile-spinner.component';

import { MobileCampaignViewComponent } from './views/mobile-campaign-view/mobile-campaign-view.component';
import { MobileProfileViewComponent } from './views/mobile-profile-view/mobile-profile-view.component';
import { MobileHomeViewComponent } from './views/mobile-home-view/mobile-home-view.component';
import { MobileCampaignsViewComponent } from './views/mobile-campaigns-view/mobile-campaigns-view.component';

import { SwiperModule } from 'ngx-swiper-wrapper';
import { TranslateModule } from '@ngx-translate/core';
import { DateDiffTime2Pipe } from './pipes/DateDiffTimePipe2';
import { MobileCheckoutViewComponent } from './views/mobile-checkout-view/mobile-checkout-view.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { FunsMobilePlaceComponent } from './components/shared/funs-mobile-place/funs-mobile-place.component';
import { FunsMobileDropdownComponent } from './components/shared/funs-mobile-dropdown/funs-mobile-dropdown.component';
import { FunsMobileInputTextComponent } from './components/shared/funs-mobile-input-text/funs-mobile-input-text.component';
import { FunsMobileInputOptionsComponent } from './components/shared/funs-mobile-input-options /funs-mobile-input-options.component';
import { FunsMobileInputTextRoundedComponent } from './components/shared/funs-mobile-input-text-rounded/funs-mobile-input-text-rounded.component';


@NgModule({
  declarations: [
    FunsMobileHeaderComponent,
    FunsMobileFooterComponent,
    FunsMobileSpinnerComponent,
    FunsMobilePlaceComponent,
    FunsMobileDropdownComponent,
    MobileCampaignViewComponent,
    MobileProfileViewComponent,
    MobileHomeViewComponent,
    MobileCampaignsViewComponent,
    FunsMobileInputTextRoundedComponent,
    DateDiffTime2Pipe,
    MobileCheckoutViewComponent,
    FunsMobileInputTextComponent,
    FunsMobileInputOptionsComponent
  ],
  exports: [
    FunsMobileHeaderComponent,
    FunsMobileFooterComponent,
    FunsMobileSpinnerComponent,
    MobileCampaignViewComponent,
    MobileProfileViewComponent,
    MobileHomeViewComponent,
    MobileCampaignsViewComponent,
    MobileCheckoutViewComponent
  ],
  imports: [
    CommonModule,
    SwiperModule,
    SharedModule,
    TranslateModule,
    FormsModule
  ]
})
export class V2FunsMobileModule { }
